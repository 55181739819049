import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import { json } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { Terminal } from "lucide-react";
import { useEffect } from "react";
import { getToast } from "remix-toast";
import { Toaster, toast as notify } from "sonner";
import "./styles/fontface.css";

import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { CustomToaster } from "@/components/ui/customtoaster";
import { cn } from "@/lib/utils";
import { getUser } from "~/session.server";
import "./styles/tailwind.css";

export const meta: MetaFunction = () => [
  {
    title: "Amanda AI",
  },
];

export const links: LinksFunction = () => {
  return [
    // add favicon
    {
      rel: "icon",
      href: "/favicon.svg",
      type: "image/svg+xml",
    },
  ];
  // return [{ rel: "icon", href: "https://c1.webien.cloud/amandaai.com-stage/app/uploads/2022/08/favicon-logo-ornage.svg", type: "image/svg+xml" }];
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const user = await getUser(request);
  const { toast, headers } = await getToast(request);
  return json(
    {
      toast,
      user,
    },
    {
      headers,
    },
  );
};

function App() {
  const { toast } = useLoaderData<typeof loader>();
  // Hook to show the toasts
  useEffect(() => {
    if (toast?.type === "error") {
      notify.custom((t) => (
        <CustomToaster.error t={t} message={toast.message} />
      ));
    }
    if (toast?.type === "success") {
      notify.custom((t) => (
        <CustomToaster.success t={t} message={toast.message} />
      ));
    }
    if (toast?.type === "info") {
      // notify.info(toast.message);
      notify.custom((t) => (
        <CustomToaster.info t={t} message={toast.message} />
      ));
    }
  }, [toast]);

  return (
    <html lang="en" className={cn("h-full")}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="relative h-full">
        <Outlet />
        <Toaster />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError();

  if (error instanceof Error) {
    return (
      <Alert variant="destructive">
        <Terminal className="h-4 w-4" />
        <AlertTitle>An unexpected error occurred</AlertTitle>
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    );
  }

  if (!isRouteErrorResponse(error)) {
    return (
      <Alert variant="destructive">
        <Terminal className="h-4 w-4" />
        <AlertTitle>An unknown error occurred</AlertTitle>
      </Alert>
    );
  }

  captureRemixErrorBoundaryError(error);

  return (
    <Alert variant="destructive">
      <Terminal className="h-4 w-4" />
      <AlertTitle>An unexpected error occurred</AlertTitle>
      <AlertDescription>{error.data}</AlertDescription>
    </Alert>
  );
}
