import { Check, X } from "lucide-react";
import { toast as notify } from "sonner";

type CustomToasterProps = React.HTMLAttributes<SVGElement> & {
  message: string;
  t: string | number;
};

export const CustomToaster = {
  info: (props: CustomToasterProps) => {
    const { message, t } = props;
    return (
      <div className="whitespace-nowrap border bg-hausbeigelight py-5 pl-5 pr-24 font-light text-opacity-70">
        <div
          data-icon
          className="relative bottom-0.5 my-0.5 mr-3 inline-flex h-5 w-5 translate-y-1 items-center justify-center rounded-full bg-hausyellow p-0.5 text-black text-opacity-70"
        >
          <span className="font-medium text-black text-opacity-50">!</span>
        </div>
        <div data-title className="inline-flex whitespace-normal font-light">
          {message}
        </div>
        <button
          onClick={() => notify.dismiss(t)}
          className="absolute right-1 top-1"
        >
          <X className="h-5 w-5" />
        </button>
      </div>
    );
  },
  success: (props: CustomToasterProps) => {
    const { message, t } = props;
    return (
      <div className="whitespace-nowrap border bg-hausbeigelight py-5 pl-5 pr-24 font-light text-opacity-70">
        <div
          data-icon
          className="relative bottom-0.5 my-0.5 mr-3 inline-flex h-5 w-5 translate-y-1 items-center justify-center rounded-full bg-hausspringgreen p-0.5 text-black text-opacity-70"
        >
          <span className="font-medium text-black text-opacity-50">
            <Check className="h-4 w-4" />
          </span>
        </div>
        <div data-title className="inline-flex whitespace-normal font-light">
          {message}
        </div>
        <button
          onClick={() => notify.dismiss(t)}
          className="absolute right-1 top-1"
        >
          <X className="h-5 w-5" />
        </button>
      </div>
    );
  },
  error: (props: CustomToasterProps) => {
    const { message, t } = props;
    return (
      <div className="border bg-hausbeigelight py-5 pl-5 pr-7 font-light text-opacity-70">
        <h5 className="w-full whitespace-nowrap">
          <div className="relative bottom-0.5 my-0.5 mr-3 inline-flex h-5 w-5 translate-y-1 items-center justify-center rounded-full bg-hausred p-0.5 text-white text-opacity-70">
            <span className="font-medium text-white text-opacity-50">!</span>
          </div>
          <span className="whitespace-nowrap">{message}</span>
        </h5>
        <button
          onClick={() => notify.dismiss(t)}
          className="absolute right-1 top-1"
        >
          <X className="h-4 w-4" />
        </button>
      </div>
    );
  },
};
